import React from 'react'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import {graphql} from "gatsby"
import NavigationList from '../components/navigation-list'
import _ from "lodash";


class ExhibitionIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const exhibitions = get(this, 'props.data.allContentfulAusstellung.edges')
    const sorted = _.orderBy(exhibitions, 'node.date', 'desc')
    return (
      <Layout location={this.props.location} showNavigation={true} path={[{text: 'Ausstellungen'}]}>
        <Helmet title={siteTitle}>
          <meta name="viewport" content="initial-scale=1, maximum-scale=1" />
        </Helmet>
        <NavigationList
          links={sorted.map((exhibition => ({path: "/ausstellung/" + exhibition.node.slug,
            text: exhibition.node.titleShort})))}>
        </NavigationList>
      </Layout>
    )
  }
}

export default ExhibitionIndex

export const pageQuery = graphql`
  query AusstellungIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulAusstellung {
      edges {
        node {
          title
          titleShort
          slug
          date
        }
      }
    }
  }
`

